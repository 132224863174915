  .form-prof {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-right: 20px;
    flex: 1;
    border-right: solid 2px #0f3952;
  }
  .profile-container {
    display: flex;
    position: relative;
    min-width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    margin: 20px;
  }
.zagolovok {
  display: flex;
  position: relative;
  background-color: #0f3952;
  padding: 10px;
  font-size: 1.4rem;
  color: aliceblue;
  border-radius: 8px;
}
.form-fio {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  flex-direction: row;
  gap: 20px;
  padding-top: 10px;
}
.check-form-ok {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;

  font-size: 1.1rem;
  gap: 10px;
  color: #054205;
}
.check-form-er {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  gap: 10px;
  color: #420505;
}
form input {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-radius: 8px;
  padding: 6px;
  font-size: 1.1rem;
  border: solid 2px #041327;

}

  .organization-list {
      margin: 20px;
      font-family: 'Roboto', sans-serif; /* Современный шрифт */
      color: var(--text-color);
  }
  
  .navigation-panel {
      display: flex;
      height: 50px;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      background-color: var(--primary-bg-color);
      padding: 10px;
      border-radius: 5px;
  }
  
  .navigation-panel button {
    padding: 5px 10px;
    height: 40px;
      border: none;
      background: none;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color var(--transition-speed);
    background: var(--primary-bg-color);
    color: #ffffff;
    /* Добавить тень для кнопок, чтобы соответствовать карточкам */
    box-shadow: var(--card-shadow);
  }
  
  .navigation-panel button.active,
  .navigation-panel button:hover {
      background-color: rgba(255, 255, 255, 0.2);
  }
  
  .navigation-panel input[type="text"] {
    border: 1px solid #ccc; /* Добавить границу для полей ввода */
    padding: 5px;
    border-radius: var(--border-radius);
  }
  
  .add-organization-button {
    background-color: var(--accent-color);
    color: white;
    /* Добавление теней и переходов для кнопки "Добавить организацию" */
    box-shadow: var(--card-shadow);
    transition: background-color var(--transition-speed), box-shadow var(--transition-speed);
  }
  
  .add-organization-button:hover {
    background-color: darken(var(--accent-color), 10%);
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
  }
  
  .organization-card {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .organization-card {
      padding: 10px;
      border-radius: 10px;
      background-color: var(--card-bg-color);
      box-shadow: var(--card-shadow);
      transition: transform var(--transition-speed), box-shadow var(--transition-speed);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #000;
  }
  
  .organization-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24);
  }
  
  .author-avatar {
    width: 100px; /* или любая другая ширина, которую вы хотите использовать */
    height: 100px; /* или любая другая высота */
    border-radius: 50%; /* Сделаем изображение круглым */
    margin-right: 10px; /* Добавим немного пространства справа от аватара */
  }
  
  .contorg {
    max-width: 200px;
  }
  
  .profile-card {
    width: 80%;
    margin: auto;
  }
  .profile-card h3 {
    margin-bottom: 20px;
  }
  
  
  .form-group {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: bold;
  }
  
  .form-input {
    width: 100%;
    padding: 0.5rem;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    transition: border-color var(--transition-speed);
  }
  
  .form-input:focus {
    border-color: var(--accent-color);
    outline: none;
  }
  
  .form-input.error {
    border-color: red;
  }
  
  .error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--accent-color);
    color: white;
    cursor: pointer;
    transition: background-color var(--transition-speed);
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  

  
  /* Стили для контейнера списка организаций */
  .organization-list-container {
    width: 300px; /* или сколько необходимо */
    margin-top: 20px;
  }
  
  /* Стиль элемента списка организаций */
  .organization-item {
    background: #f9f9f9;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .modal-message .modal-overlay {
    background-color: unset !important;
    align-items: center;
    text-align: center;
    justify-content:center;
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  
  .modal-message-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content:center;
  }
  
  .modal-message-text {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .modal-message-check {
    font-size: 20px;
    color: #00ff00;
  }
  
  .modal-message-error {
    background-color: #ff0000;
  }
  
  .modal-message-success {
    display: flex;
    position: fixed;
    background-color: #00ff002f;
    top: auto;
    bottom: 30px;
    right: 20px;
    border: solid 2px rgb(89, 224, 89);
    color: #283f285e;
    min-height: 50px;
    min-width: 100px;
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
    border-radius: 8px;
    align-items: center;
    text-align: center;
    justify-items: center;
    padding: 5px;
  }
  