.loading-screen {
    height: 100vh;
    width: 100vw;
    background-color: rgb(8, 35, 61);
    display: flex;
    position: relative;
    align-items: center;
    justify-items: center;

}

.block-screen {
    margin: auto;
    color: aliceblue;
    display: flex;
    position: relative;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}
.bloc-load {
  display: flex;
    position: relative;
    align-items: center;
    justify-items: center;
    flex-direction: row;
}
.load-logo {
    height: 40vmin;
    pointer-events: none;
  }
.load-anim {
  display: flex;
  position: absolute;
  height: 20rem;
  width: 20rem;
  top:10px;
}
  @media (prefers-reduced-motion: no-preference) {
    .load-logo {
      animation: load-logo-spin infinite 20s linear;
    }
  }