.service-updates-container {
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  margin: 10px;
}

.block-name {
  display: flex;
  width: 30%;
  color: aliceblue;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
}

.input-area {
  display: flex;
  position: relative;
  min-height: 100px;
  width: 97%;
  justify-content: space-between;
  border-radius: 8px;
  padding: 6px;
  font-size: 1rem;
  border: none;
  overflow: auto;
  outline: none;
  resize: none;
}

.service-updates-list {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.update-card {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 8px;
  background-color: #e2e2e27e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
}

.card-header {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  color: black;
  border-bottom: solid 1px #818181;
}

.update-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24);
}

.modal-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.modal-form h2 {
  margin-bottom: 10px;
  text-align: center;
  color: #333;
}

.modal-form form {
  display: flex;
  flex-direction: column;
}

.modal-form label {
  margin-bottom: 5px;
  color: #555;
}

.modal-form textarea {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-buttons button {
  display: flex;
    text-decoration: none;
    left: 0;
    right: auto;
    padding: 0 10px;
    width: 100px;
    border-radius: 8px;
    height: 50px;
    align-items: center;
    justify-content: center;
      border: none;
      background: none;
      cursor: pointer;
      transition: background-color var(--transition-speed);
    background: var(--primary-bg-color);
    color: #ffffff;
    /* Добавить тень для кнопок, чтобы соответствовать карточкам */
    box-shadow: var(--card-shadow);
}

.modal-buttons button:hover {
  background-color: rgb(32, 90, 165);
}