.page{
  display: flex;
  flex: 1;
}

.formzay {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-right: 20px;
  flex: 1;
}
.form-zay {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
}
.card-container-bs {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    background-color: #f5f5f5;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .buttons {
    display: flex;
    text-decoration: none;
    left: 0;
    right: auto;
    padding: 0 10px;
    width: 100px;
    border-radius: 8px;
    height: 50px;
    align-items: center;
    justify-content: center;
      border: none;
      background: none;
      cursor: pointer;
      transition: background-color var(--transition-speed);
    background: var(--primary-bg-color);
    color: #ffffff;
    /* Добавить тень для кнопок, чтобы соответствовать карточкам */
    box-shadow: var(--card-shadow);
  }
  .buttons:hover {
    background-color: rgb(32, 90, 165);
}
.form-fio {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  flex-direction: row;
  gap: 20px;
  padding-top: 10px;
}
.input {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-radius: 8px;
  padding: 6px;
  font-size: 1rem;
  border: solid 1px #041327;
}
.zagolovok {
  flex: 1;
  max-height: 30px;
}
.input-area {
  display: flex;
  position: relative;
  min-height: 100px;
  justify-content: space-between;
  border-radius: 8px;
  padding: 6px;
  font-size: 1rem;
  border: solid 1.3px #041327;
}
.form-group-status {
  display: flex;
  height: 100%;
  width: 150px;
  flex-direction: column;
}
.status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  height: 100%;
  border-radius: 8px;
  color: aliceblue;
  text-shadow: #041327 1px 0 10px;
  background-color: rgba(85, 85, 85, 0.589);
  font-size: 1.1rem;
}