:root {
  --primary-bg-color: #0f3952; /* Обновленный светлый фон */
  --text-color: #333; /* Цвет текста */
  --accent-color: #103c77; /* Акцентный цвет для выделения */
  --card-bg-color: #dad8d8; /* Фон карточки */
  --card-shadow: 0 6px 20px rgba(0,0,0,0.1); /* Усовершенствованная тень для карточки */
  --border-radius: 8px; /* Радиус скругления */
  --transition-speed: 0.3s; /* Скорость анимации */
}

.App {
  display: flex; 
  position: relative; 
  flex-direction: column; 
  min-height: 100vh;
  max-width: 100vw;
}
