/* header.css */ .Header { height: 100px; background-image: linear-gradient(90deg, #0c2f47, #031420, #0c2f47); display: flex; align-items: center; justify-content: space-between; color: white; padding: 0 20px; }

.company-info { display: flex; gap: 20px; flex: 1; text-align: center; font-size: 1.5rem; flex-direction: row; justify-content: center;}

.logo-header { display: flex; background-color: aliceblue; height: 70px; width: 70px; border-radius: 50%; align-items: center; justify-content: center; border: solid 2px #031420; box-shadow: 0 0 10px rgba(0, 0, 0, 0.8); }

.load-logo-header { width: 60px; height: 60px; }

.line { display: flex; border-left: solid 1px #fff; padding-left: 20px;}

.contact-block { display: flex; align-items: left; flex-direction: column;}

.contact-info { display: flex; align-items: center; font-size: 1rem; margin-right: 20px; }

.contact-link { text-decoration: none; color: white; }